.authorization__wrapper {
    max-width: 1920px;
    margin: 0 auto auto;
    display: grid;
    grid-template-columns: 1009px 911px;
    overflow-x: hidden;
    height: 100%;

    .authorization__img {
        background: url('../../../../public/images/authorization-image.png') no-repeat center;
        background-size: cover;
        padding: 90px 10px 0;

        img {
            width: 295px;
        }
    }


    .authorization-form__wrapper {
        padding: 60px 105px 0 93px;

        .agreement-error {
            color: red;
            margin-top: -10px;
            margin-bottom: 10px;
            font-size: 14px;
        }

        .authorization__toggle {
            margin-bottom: 30px;

            a {
                @include font-params (700, 32px, 39px);
                margin-right: 100px;
                padding: 10px 13px;

                &.active {
                    border-bottom: 1px solid #F98600;
                }
            }
        }

        .authorization-form,
        .registration-form {
            display: flex;
            flex-direction: column;

            label {
                @include font-params (400, 32px, 39px);

                &:last-child {
                    margin-bottom: 15px;
                }

                input {
                    margin-top: 20px;
                    display: block;
                    width: 713px;
                    padding: 12px 20px;
                    @include font-params (400, 32px, 39px);
                    border: 1px solid #F98600;
                    border-radius: 5px;
                    outline: none;

                    &.error {
                        border-color: red;
                    }

                    &::placeholder {
                        color: $link_color;
                    }
                }

                &.password {
                    position: relative;

                    input {
                        color: #F98600;

                        &.active {
                            color: $link_color;
                        }
                    }

                    p {
                        width: 65px;
                        height: 65px;
                        position: absolute;
                        top: 60px;
                        right: 12px;
                        background: url('../../../../public/images/hidden-password.svg') no-repeat center;
                        outline: none;

                        &.active {
                            background: url('../../../../public/images/view-password.svg') no-repeat center;
                        }
                    }
                }
            }

            .error-message {
                height: 20px;
                color: red;
                @include font-params (400, 20px, 20px);
                &:first-child {
                    text-align: center;
                }
            }

            .remember,
            .apply-agreement {
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                margin-bottom: 70px;

                label {
                    @include font-params (400, 24px, 29px);

                    input {
                     //   opacity: 0;
                        z-index: -1;
                        margin: 0;
                        margin-right: 20px;
                        display: inline-block;
                        width: 45px;
                        height: 45px;

                        vertical-align: middle;

                        &::before+label {
                            content: '';
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            border: 1px solid #F98600;
                            border-radius: 5px;
                        }

                        &.error {
                            border-color: red;
                        }
                    }
                }

                button {
                    @include font-params (400, 24px, 29px);
                    color: #0DA500;
                }
            }

            input[type=submit] {
                width: 266px;
                height: 75px;
                border: none;
                background-color: #F98600;
                border-radius: 5px;
                color: #fff;
                @include font-params (500, 28px, 34px);
                margin: auto;
            }
        }
    }

}

@media only screen and (max-width: 1919px) and (min-width: 1024px) {
    .authorization__wrapper {
        max-width: 1024px;
        grid-template-columns: 496px 528px;

        .authorization__img {
            background-size: cover;
            padding: 53px 13px 410px;

            img {
                width: 148px;
            }
        }

        .authorization-form__wrapper {
            padding: 28px 50px 0 30px;

            .authorization__toggle {
                margin-bottom: 15px;

                a {
                    @include font-params (700, 22px, 27px);
                    margin-right: 50px;
                    padding: 5px 13px;
                }
            }


            .registration-form {
                padding-bottom: 115px;
            }

            .authorization-form,
            .registration-form {
                label {
                    @include font-params (400, 22px, 27px);

                    input {
                        margin-top: 10px;
                        width: 432px;
                        padding: 11px 12px;
                        @include font-params (400, 22px, 27px);

                        &::placeholder {
                            color: $link_color;
                        }
                    }

                    &.password {
                        p {
                            width: 30px;
                            height: 28px;
                            top: 50px;
                            right: 25px;
                            background-size: cover;

                            &.active {
                                background-size: cover;
                            }
                        }
                    }
                }

                .error-message {
                    @include font-params (400, 14px, 20px);
                }

                .remember,
                .apply-agreement {
                    margin-bottom: 30px;

                    label {
                        @include font-params (400, 16px, 19px);

                        input {
                            margin-right: 15px;
                            width: 30px;
                            height: 30px;
                        }
                    }

                    button {
                        @include font-params (400, 16px, 19px);
                    }
                }

                input[type=submit] {
                    width: 179px;
                    height: 58px;
                    @include font-params (500, 22px, 27px);
                }
            }
        }

    }
}

@media only screen and (max-width: 1023px) and (min-width: 280px) {
    .authorization__wrapper {
        display: flex;
        max-width: 460px;
        margin: 0 auto auto;
        flex-direction: column;
        width: 100%;

        .authorization__img {
            background-size: cover;
            padding: 31px 57px 138px;
            width: 100%;

            img {
                width: 100px;
            }
        }

        .authorization-form__wrapper {
            padding: 15px;
            max-width: 360px;
            margin: 0 auto auto;

            .authorization__toggle {
                margin-bottom: 15px;

                a {
                    @include font-params (700, 16px, 19px);
                    margin-right: 24px;
                    padding: 5px 8px;
                }
            }

            .authorization-form,
            .registration-form {
                display: flex;
                flex-direction: column;

                label {
                    @include font-params (400, 16px, 19px);

                    &:last-child {
                        margin-bottom: 0;
                    }

                    input {
                        margin-top: 5px;
                        width: 330px;
                        padding: 10px;
                        @include font-params (400, 16px, 19px);
                    }

                    &.password {
                        p {
                            width: 20px;
                            height: 20px;
                            top: 34px;
                            right: 10px;
                            background-size: 20px;

                            &.active {
                                background-size: 20px;
                            }
                        }
                    }
                }

                .error-message {
                    height: 15px;
                    color: red;
                    @include font-params (400, 12px, 15px);
                }

                .remember,
                .apply-agreement {
                    margin-bottom: 20px;

                    label {
                        @include font-params (400, 12px, 15px);

                        input {
                            margin-right: 15px;
                            width: 30px;
                            height: 30px;
                        }
                    }

                    button {
                        @include font-params (400, 12px, 15px);
                    }
                }

                input[type=submit] {
                    width: 148px;
                    height: 39px;
                    @include font-params (500, 16px, 19px);
                }
            }
        }
    }
}
