.ModelCatalog {
  font-family: Arial, sans-serif;
  display: flex;
  column-gap: 10px;

  .aside-wrapper {
    flex: 0 1 450px;
  }
}

.category {
  margin-bottom: 10px;
}
.category.selected {
  background-color: #e0f7fa; /* Светло-голубой фон */
  border-left: 4px solid #00bcd4; /* Синяя рамка слева */
}

.category.selected .category-title {
  font-weight: bold; /* Жирный шрифт для заголовка */
}
.products {
  flex: 0 0 60%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.analogue__block {
  border: 1px solid black;
}

.analogue__block-sum {
  font-size: 24px;
  font-weight: 500;
  padding: 10px;
}
.analogue__block_cart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 10px;
}
.add__cart {
  color: #fff;
  background-color: #F98600;
  border-radius: 5px;
  width: 250px;
  height: 50px;
}


.photo__wrapper {
  max-width: 150px;

  img {
    max-width: 100%;
  }
}

.analogue__wrapper {
  display: flex;
  align-items: center;

}

.category-title {
  cursor: pointer;
  padding: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
}

.category-title:hover {
  background-color: #e0e0e0;
}

.subcategory-list {
  list-style: none;
  margin-left: 20px;
}

/////////////////////////

.accordeon {
  .accordeon-title {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 4px;
    user-select: none;
  }

  .arrow {
    font-size: 16px;
    margin-left: 8px;
    transition: transform 0.3s ease-in-out;

    &.up {
      transform: rotate(180deg);
    }
  }

  p {
    margin: 0;
    padding: 5px;
    border-top: 1px solid #ddd;

    &:last-child {
      border-bottom: 1px solid #ddd;
    }
  }
}

