.personal_area {
  @include block-pos;
  display: flex;
  padding: 30px 0 70px;
  justify-content: space-between;
  width: 100%;

  nav {
    margin-right: 50px;
    max-width: 407px;

    a {
      @include font-params(400, 32px, 39px);
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      padding-bottom: 10px;

      &.active {
        font-weight: 600;
        border-bottom: 1px solid #F98600;
      }

      &::before {
        display: inline-block;
        content: '';
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }

      &.private::before {
        background: url(../../../../public/images/privacy-area/private-icon.svg) no-repeat center;
      }

      &.orders::before {
        background: url(../../../../public/images/privacy-area/order-icon.svg) no-repeat center;
      }

      &.agreement::before {
        background: url(../../../../public/images/privacy-area/agreement-icon.svg) no-repeat center;
      }

    }
  }

  .personal_info {
    display: flex;

    p {
      @include font-params(500, 32px, 39px);
      margin-bottom: 20px;

      span {
        font-weight: 400;
      }
    }


    .about_person {
      .person_name {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        p {
          &:first-child {
            font-weight: 700;

            span {
              font-weight: 700;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

      }

      .contacts {
        display: flex;
        justify-content: space-between;

        p {
          span {
            display: block;
          }
        }
      }
    }

    button {
      position: absolute;
      left: 74%;
      transform: translateY(-10px);
      width: 64px;
      height: 64px;
      background: url('../../../../public/images/editor-icon.svg') no-repeat center;
    }
  }

  form {
    .edit__about_person {
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        flex-direction: column;

        label {
          @include font-params(500, 32px, 39px);
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 50px;
          }

          input {
            margin-top: 15px;
            @include font-params(500, 32px, 39px);
            display: block;
            width: 845px;
            padding: 12px 20px;
            border-radius: 5px;
            border: 1px solid #F98600;
            outline: none;
          }
        }
      }

      > input {
        align-self: center;
        width: 405px;
        height: 75px;
        color: #fff;
        @include font-params(500, 28px, 34px);
        border: none;
        background-color: #F98600;
        border-radius: 5px;
        outline: none;
        cursor: pointer;
      }

    }
  }
}

@media only screen and (max-width: 1919px) and (min-width: 1024px) {
  .personal_area {
    @include block-pos-laptop;
    padding: 27px 0 40px;

    nav {
      margin-right: 20px;
      max-width: 315px;

      a {
        @include font-params(400, 22px, 27px);
        margin-bottom: 20px;
        padding-bottom: 10px;

        &::before {
          width: 40px;
          height: 40px;
          margin-right: 15px;
        }

        &.private::before {
          background-size: 40px;
        }

        &.orders::before {
          background-size: 40px;
        }

        &.agreement::before {
          background-size: 40px;
        }

      }
    }

    .personal_info {
      p {
        @include font-params(500, 20px, 24px);
        margin-bottom: 15px;
      }


      .about_person {

        .person_name {
          p {
            &:first-child {
              @include font-params(700, 22px, 27px);
            }
          }

        }
      }

      button {
        left: 69%;
        transform: translateY(-5px);
        width: 40px;
        height: 40px;
        background-size: 40px;
      }
    }

    form {
      .edit__about_person {
        div {
          label {
            @include font-params(500, 22px, 27px);
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 40px;
            }

            input {
              margin-top: 10px;
              @include font-params(500, 22px, 27px);
              width: 453px;
              padding: 10px 20px;
            }
          }
        }

        > input {
          width: 316px;
          height: 58px;
          @include font-params(500, 22px, 27px);
        }

      }
    }
  }
}

@media only screen and (max-width: 1023px) and (min-width: 280px) {
  .personal_area {
    @include block-pos-mobile;
    flex-direction: column;
    height: 100%;
    padding: 0 0 20px;
    justify-content: space-between;

    nav {
      margin-right: 0;
      max-width: 330px;

      a {
        @include font-params(400, 16px, 19px);
        margin-bottom: 10px;
        padding-bottom: 10px;

        &::before {
          width: 30px;
          height: 30px;
          margin-right: 15px;
        }

        &.private::before {
          background-size: 30px;
        }

        &.orders::before {
          background-size: 30px;
        }

        &.agreement::before {
          background-size: 30px;
        }

      }
    }

    .personal_info {
      display: flex;

      p {
        @include font-params(500, 16px, 19px);
        margin-bottom: 10px;

        span {
          font-weight: 400;
        }
      }


      .about_person {
        .person_name {
          display: flex;
          flex-direction: column;
          margin-bottom: 0;

          p {
            &:first-child {
              font-weight: 700;

              span {
                font-weight: 700;
              }
            }
          }

        }

        .contacts {
          display: flex;
          justify-content: space-between;

          p {
            span {
              display: block;
            }
          }
        }
      }

      button {
        position: absolute;
        left: 66%;
        transform: translateY(-3px);
        width: 30px;
        height: 30px;
        background-size: 30px;
      }
    }

    form {
      .edit__about_person {
        div {
          label {
            @include font-params(500, 16px, 19px);
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 20px;
            }

            input {
              margin-top: 10px;
              @include font-params(500, 14px, 17px);
              width: 330px;
              padding: 10px;
            }
          }
        }

        > input {
          align-self: center;
          width: 227px;
          height: 48px;
          @include font-params(500, 16px, 19px);
        }

      }
    }
  }
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-status {
  padding: 8px;

  .completed {
    background-color: #97F48F;
  }

  &.not-completed {

    background-color: #FFE976;
  }
}
