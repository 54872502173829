.news {
    @include block-pos;
    padding: 30px 0 70px;

    p {
        @include font-params (400, 28px, 34px);
        margin-bottom: 20px;
    }

    h2 {
        @include font-params (700, 32px, 39px);
        margin-bottom: 20px;
    }

    .article {
        display: grid;
        grid-gap: 50px;
        grid-template-rows: 740px 740px 740px;

        .article_block {
            display: grid;
            grid-template-columns: 1018px 646px;
            grid-gap: 45px;

            img {
                grid-column: 2;
                grid-row: 1;
            }

            p {
                grid-column: 1;
            }

            &:first-child,
            &:last-child {
                grid-template-columns: 646px 1018px;

                img {
                    grid-column: 1;
                }

                p {
                    grid-column: 2;
                }
            }
        }
    }
}