.model-catalog {
    position: relative;
    @include block-pos;
    padding: 6px 20px 70px;
    //display: flex;

    >h1 {
        text-transform: capitalize;
        margin-bottom: 40px;
    }

    .model-catalog__wrapper {
        display: grid;
        grid-template-columns: 24% 74%;
        // grid-gap: 30px;
        justify-content: space-between;

        aside {
            @include font-params (500, 26px, 31px);
            padding: 30px 15px;
            border: 1px solid #F98600;
            border-radius: 5px;
            height: max-content;

            ul {
                li {
                    margin-bottom: 15px;
                    position: relative;

                    &.open {
                        color: #F98600;

                        li {
                            color: $link_color;
                        }
                    }

                    .model-catalog_button {
                        width: 100%;
                        height: 30px;
                        position: absolute;
                        background: url('../../../../public/images/accordion-catalog-arrow.svg') no-repeat right;
                        background-size: 15px;
                        top: 0;
                        right: 0;
                        cursor: pointer;
                        z-index: 1;
                        outline: none;

                        &.active {
                            top: 100%;
                            background: url('../../../../public/images/hidden-button.svg') no-repeat right;
                            background-size: 100px;
                            background-position-x: 90%;
                            transform: translateY(-100%);

                            span {
                                display: block;
                                position: absolute;
                                top: 0;
                                right: 0;
                                width: 15px;
                                height: 30px;
                                background: url('../../../../public/images/accordion-catalog-arrow.svg') no-repeat center;
                                background-size: 15px;
                                transform: rotate(180deg);
                                z-index: 0;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .spares-brand {
                margin-top: 10px;
                padding-left: 20px;
                padding-bottom: 40px;
                border-left: 1px solid #F98600;

                li {
                    &.open {
                        color: #F98600;

                        li {
                            color: $link_color;
                        }
                    }
                }

                .spares-categories {
                    margin-top: 10px;
                    padding-left: 20px;
                    padding-bottom: 40px;
                    border-left: 1px solid #F98600;

                    li {
                        color: $link_color;
                        cursor: pointer;

                        &.active {
                            color: #F98600;
                        }
                    }
                }
            }
        }

        >div {
            .product-group__wrapper {
                border: 1px solid #F98600;
                border-radius: 5px;
                padding: 20px;
                position: relative;
                margin-bottom: 30px;

                .product-group {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    >div {

                        h2 {
                            @include font-params(600, 28px, 34px);
                            margin-bottom: 30px;
                        }

                        p {
                            @include font-params(500, 28px, 34px);
                            margin-bottom: 20px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }

                    button {
                        color: #F98600;
                        @include font-params(600, 28px, 34px);
                        position: absolute;
                        right: 20px;
                        bottom: 20px;

                        &::after {
                            content: '';
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            background: url('../../../../public/images/accordion-catalog-arrow.svg') no-repeat center;
                            vertical-align: middle;
                            margin-left: 15px;
                        }

                        &.active {
                            &::after {
                                transform: rotate(180deg);
                            }

                            &.sub {
                                bottom: 20px;
                            }
                        }
                    }
                }

                .analogue-products {
                    padding-top: 45px;
                    padding-left: 50px;

                    &.open {
                        display: block;

                        .analogue__wrapper {
                            display: flex;
                        }
                    }

                    &.hidden {
                        display: none;
                    }

                    >div {
                        margin-bottom: 70px;
                    }

                    .analogue__block {
                        position: relative;
                        margin-bottom: 30px;

                    }

                    .analogue__wrapper {
                        display: none;
                        align-items: center;


                        .photo__wrapper {
                            border: 2px solid $link_color;
                            border-radius: 5px;
                            padding: 31px 46px;
                            margin-right: 27px;

                            img {
                                width: 100px;
                            }
                        }

                        h2 {
                            @include font-params(600, 28px, 34px);
                            margin-bottom: 30px;
                        }

                        p {
                            @include font-params(500, 28px, 34px);
                            margin-bottom: 15px;

                            &.price {
                                margin-top: 45px;
                                margin-bottom: 0;
                                color: #0DA500;
                            }
                        }
                    }

                    >div {
                        position: relative;

                        button {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            color: #F98600;
                            @include font-params(500, 28px, 34px);

                            &::after {
                                content: '';
                                width: 20px;
                                height: 20px;
                                display: inline-block;
                                background: url('../../../../public/images/accordion-catalog-arrow.svg') no-repeat center;
                                vertical-align: middle;
                                margin-left: 15px;
                            }

                            &.active {
                                &::after {
                                    transform: rotate(180deg);
                                }

                            }
                        }
                    }

                    .in-stock {
                        padding-left: 222px;
                        padding-top: 45px;

                        &.hidden {
                            display: none;
                        }

                        &.open {
                            display: flex;
                            flex-direction: column;
                            padding-bottom: 50px;
                        }

                        h2 {
                            @include font-params(500, 32px, 39px);
                            margin-bottom: 20px;
                        }

                        p {
                            display: flex;
                            justify-content: space-between;
                            @include font-params(400, 28px, 34px);
                            margin-bottom: 20px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}