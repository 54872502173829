footer {
    .footer__wrapper {
        background-color: #F98600;

        .footer__flex {
            max-width: 1554px;
            margin: auto;
            background-color: #fff;
            display: flex;
            padding: 20px 50px;
            justify-content: space-between;


            h2 {
                @include font-params (700, 26px, 31px);
                margin-bottom: 15px;
            }

            p {
                @include font-params (400, 20px, 24px);
                margin-bottom: 10px;

                span {
                    margin-left: 15px;
                }

                &::before {
                    display: inline-block;
                    content: '';
                    width: 20px;
                    height: 40px;
                    vertical-align: middle;
                    margin-right: 15px;
                }

                &.phone-number::before {
                    background: url('../../../public/images/phone-icon.svg') no-repeat center;
                }

                &.working-hours::before {
                    background: url('../../../public/images/working-hours-icon.svg') no-repeat center;
                }

                &.mail::before {
                    background: url('../../../public/images/mail-icon.svg') no-repeat center;
                }
            }


            p {
                &.working-hours::before {
                    width: 32px;
                }

                &.mail::before {
                    width: 25px;
                }
            }

            img {
                width: 225px;
                height: 167px;
            }

        }
    }

    nav {
        background: rgba(249, 134, 0, 0.4);

        ul {
            @include block-pos;
            display: flex;
            height: 122px;

            a {
                margin-right: 65px;
                height: 100%;
                padding-top: 44px;

                li {
                    @include font-params (400, 28px, 34px);
                }
            }
        }
    }
}

@media only screen and (max-width: 1919px) and (min-width: 1024px) {
    footer {
        .footer__wrapper {
            .footer__flex {
                max-width: 864px;
                padding: 20px;

                h2 {
                    @include font-params (700, 22px, 26px);
                }

                p {
                    @include font-params (400, 16px, 19px);

                    span {
                        margin-left: 0;
                    }
                }

                img {
                    width: 150px;
                    height: 112px;
                }

            }
        }

        nav {
            background: rgba(249, 134, 0, 0.4);

            ul {
                @include block-pos-laptop;
                display: flex;
                height: 78px;

                a {
                    height: 100%;
                    padding-top: 30px;
                    margin-right: 34px;

                    li {
                        @include font-params (400, 16px, 19px);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1023px) and (min-width: 280px) {
    footer {
        .footer__wrapper {
            background-color: #F98600;

            .footer__flex {
                @include block-pos-mobile;
                background-color: #fff;
                flex-direction: column;
                padding: 10px 25px;
                justify-content: space-between;
                position: relative;

                h2 {
                    @include font-params (700, 16px, 19px);
                    margin-bottom: 15px;
                }

                p {
                    @include font-params (400, 12px, 14px);
                    margin-bottom: 10px;

                    span {
                        margin-left: 0;
                    }

                    &::before {
                        width: 20px;
                        height: 20px;
                        vertical-align: middle;
                        margin-right: 10px;
                    }

                    &.phone-number::before {
                        background-size: 20px;
                    }

                    &.working-hours::before {
                        background-size: 20px;
                    }

                    &.mail::before {
                        background-size: 20px;
                    }
                }


                p {
                    &.working-hours::before {
                        width: 20px;
                    }

                    &.mail::before {
                        width: 20px;
                    }
                }

                img {
                    width: 88px;
                    height: 66px;
                    margin: auto;
                    position: absolute;
                    right: 25px;
                    bottom: 10px;
                }

            }
        }

        nav {
            display: none;
        }
    }
}