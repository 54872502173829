$link_color: #000;

@mixin block-pos {
    max-width: 1750px;
    margin: 0 auto auto;
}

@mixin block-pos-laptop {
    max-width: 924px;
    margin: 0 auto auto;
}

@mixin block-pos-mobile {
    max-width: 330px;
    margin: 0 auto auto;
}

@mixin font-params ($weight, $size, $line-height) {
    font-weight: $weight;
    font-size: $size; 
    line-height: $line-height;   
}