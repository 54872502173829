header {
    background: rgba(249, 134, 0, 0.5);
    margin-bottom: 40px;

    .header__wrapper {
        @include block-pos;
        display: flex;
        padding: 0 20px;

        .header_mobile {
            margin-right: 49px;

            a {
                display: block;
                padding-top: 14px;
            }

            .hamburger-button {
                display: none;
                outline: none;
                width: 41px;
                -webkit-tap-highlight-color: transparent;

                span {
                    display: block;
                    width: 40px;
                    height: 1px;
                    margin-bottom: 9px;
                    background-color: $link_color;
                }
            }
        }

        .nav__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            @include font-params (400, 24px, 29px);

            nav {
                display: flex;
                align-items: center;
                height: 132px;

                ul {
                    display: flex;
                    height: 100%;

                    a {
                        padding: 51px 10px 52px;
                        margin-right: 46px;
                        display: block;
                        height: 100%;

                        &.active {
                            border-bottom: 3px solid #000;
                        }
                    }
                }
            }

            .header_buttons {
                display: flex;

                >a,
                >button {
                    &::before {
                        content: '';
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        background-position: center;
                        background-repeat: no-repeat;
                        vertical-align: middle;
                        margin-right: 25px;
                        outline: none;
                    }

                    &.authorization_button {
                        &::before {
                            background-image: url('../../../public/images/user-logo.svg');
                        }
                    }

                    &.cart_button {
                        color: #FF0000;
                        margin-left: 64px;

                        &::before {
                            background-image: url('../../../public/images/cart-logo.svg');
                        }
                    }
                }

                .authorization_menu {
                    position: absolute;
                    top: 132px;
                    background-color: #fcc27b;
                    border: 1px #000 solid;

                    a,
                    button {
                        display: block;
                        padding: 15px;
                        @include font-params (400, 20px, 24px);
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 1400px) and (min-width: 1024px) {
    header {
        margin-bottom: 13px;

        .header__wrapper {
            // @include block-pos-laptop;
            width: 100%;
            padding: 0 20px;

            .header_mobile {
                margin-right: 35px;

                >a {
                    padding-top: 10px;

                    img {
                        width: 79px;
                        height: 58px;
                    }
                }
            }

            .nav__wrapper {
                @include font-params (400, 16px, 19px);

                nav {
                    height: 78px;

                    ul {

                        a {
                            padding: 30px 5px;
                            margin-right: 19px;
                        }
                    }
                }

                .header_buttons {

                    >a,
                    >button {
                        @include font-params (400, 16px, 19px);

                        &::before {
                            width: 30px;
                            height: 30px;
                            margin-right: 20px;
                            background-size: 30px;
                        }

                        &.cart_button {
                            margin-left: 25px;
                        }
                    }

                    .authorization_menu {
                        top: 78px;

                        a,
                        button {
                            padding: 10px;
                            @include font-params (400, 16px, 19px);
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1023px) and (min-width: 280px) {
    header {
        background: #fcc27b;
        margin-bottom: 20px;
        z-index: 10;
        width: 100%;
        position: -webkit-sticky;
        position: sticky;
        top: 0;

        .header__wrapper {
            // @include block-pos-mobile;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 15px;

            .header_mobile {
                display: flex;
                justify-content: space-between;
                width: 100%;
                margin-right: 0;

                >a {
                    padding-top: 10px;
                    margin-right: 0;
                    height: 78px;

                    img {
                        height: 58px;
                    }
                }

                .hamburger-button {
                    display: block;
                    padding-top: 7px;

                    &.open {
                        span:nth-of-type(1) {
                            transform: rotate(45deg) translate(9px, 10px);
                        }

                        span:nth-of-type(2) {
                            opacity: 0;
                        }

                        span:nth-of-type(3) {
                            transform: rotate(-45deg) translate(5px, -5px);
                        }
                    }
                }
            }

            .nav__wrapper {
                display: none;
                flex-direction: column;
                padding-top: 0;
                align-items: flex-start;
                width: 100%;
                @include font-params (400, 16px, 19px);

                &.active {
                    display: flex;
                    position: absolute;
                    top: 78px;
                    background: #fcc27b;
                    width: 330px;
                    padding: 0 20px;
                }

                nav {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: auto;
                    width: 100%;

                    ul {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        width: 100%;

                        a {
                            padding: 10px 0;
                            margin-right: 0;
                            height: 100%;
                            width: 100%;
                            border-bottom: 1px solid rgba($color: #000000, $alpha: .06);

                            &.active {
                                border-bottom: 3px solid #000;
                            }
                        }
                    }
                }

                .header_buttons {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    >a {
                        border-bottom: 1px solid rgba($color: #000000, $alpha: .06);

                    }

                    >a,
                    >button {
                        @include font-params (400, 16px, 19px);
                        text-align: start;

                        &::before {
                            content: '';
                            display: inline-block;
                            width: 40px;
                            height: 40px;
                            background-position-x: 0px;
                            background-repeat: no-repeat;
                            background-size: 25px;
                            margin-right: 0;
                        }

                        &.authorization_button {
                            &::before {
                                background-image: url('../../../public/images/user-logo.svg');
                            }
                        }

                        &.cart_button {
                            color: #FF0000;
                            margin-left: 0;
                            text-align: start;
                            @include font-params (400, 16px, 19px);

                            &::before {
                                background-image: url('../../../public/images/cart-logo.svg');
                            }
                        }
                    }

                    .authorization_menu {
                        position: absolute;
                        top: 132px;
                        background-color: #fcc27b;
                        border: 1px #000 solid;

                        a,
                        button {
                            display: block;
                            padding: 15px;
                            @include font-params (400, 20px, 24px);
                        }
                    }
                }
            }
        }
    }
}