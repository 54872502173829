.popular-products {
    @include block-pos;
    padding-top: 70px;

    h3 {
        text-align: center;
        @include font-params (700, 32px, 39px);
        margin-bottom: 60px;
    }

    .products__flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .product__wrapper {
            width: 550px;
            margin-bottom: 60px;

            .img-wrapper {
                height: 484px;
                width: 100%;
                padding: 98px 129px;
                margin-bottom: 25px;

                img {
                    width: 287px;
                    height: 287px;
                }
            }

            .product__description {

                h4 {
                    @include font-params (600, 28px, 34px);
                    margin-bottom: 25px;
                }

                p {
                    @include font-params (500, 28px, 34px);
                    margin-bottom: 15px;

                    &:last-child {
                        margin-bottom: 33px;
                    }
                }
            }

            .product__footer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 3px;

                >p {
                    color: #0DA500;
                    @include font-params (500, 36px, 44px);
                }

                div {
                    border: 1px solid #F98600;
                    border-radius: 5px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 148px;
                    height: 56px;
                    padding: 0 20px;
                    margin-right: 15px;

                    p {
                        @include font-params (400, 36px, 44px);
                    }

                    button {
                        @include font-params (500, 36px, 44px);

                        &.decrement::before {
                            content: '-';
                            position: relative;
                            top: 1px;
                        }

                        &.increment::before {
                            content: '+';
                        }
                    }
                }

                .add-to-cart {
                    background: url('../../../../public/images/add-to-cart.svg') no-repeat center;
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .mobile-products__wrapper {
            display: none;
        }
    }
}

@media only screen and (max-width: 1919px) and (min-width: 1024px) {
    .popular-products {
        @include block-pos-laptop;
        padding-top: 40px;

        h3 {
            @include font-params (700, 22px, 27px);
            margin-bottom: 30px;
        }

        .products__flex {
            .product__wrapper {
                width: 295px;
                margin-bottom: 30px;

                .img-wrapper {
                    height: 260px;
                    padding: 53px 70px;
                    margin-bottom: 20px;

                    img {
                        width: 154px;
                        height: 154px;
                    }
                }

                .product__description {

                    h4 {
                        @include font-params (600, 20px, 24px);
                        margin-bottom: 15px;
                    }

                    p {
                        @include font-params (500, 18px, 22px);

                        &:last-child {
                            margin-bottom: 20px;
                        }
                    }
                }

                .product__footer {
                    padding-right: 3px;

                    >p {
                        @include font-params (500, 20px, 24px);
                    }

                    div {
                        width: 97px;
                        height: 38px;
                        padding: 5px 10px;
                        margin-right: 0;

                        p {
                            @include font-params (400, 22px, 27px);
                        }

                        button {
                            @include font-params (400, 22px, 27px);
                        }
                    }

                    .add-to-cart {
                        width: 30px;
                        height: 30px;
                        background-size: 30px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1023px) and (min-width: 280px) {
    .popular-products {
        @include block-pos-mobile;
        padding-top: 20px;

        h3 {
            @include font-params (700, 16px, 19px);
            margin-bottom: 13px;
        }

        .products__flex {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .product__wrapper {
                width: 241px;
                display: none;

                .img-wrapper {
                    height: 136px;
                    width: 154px;
                    padding: 28px 37px;
                    margin: auto;
                    margin-bottom: 15px;

                    img {
                        width: 80px;
                        height: 80px;
                    }
                }

                .product__description {
                    width: 241px;
                    margin: 0 auto;

                    h4 {
                        @include font-params (600, 16px, 19px);
                        margin-bottom: 10px;
                    }

                    p {
                        @include font-params (500, 14px, 17px);
                        margin-bottom: 10px;

                        &:last-child {
                            margin-bottom: 15px;
                        }
                    }
                }

                .product__footer {
                    padding-right: 3px;
                    width: 241px;
                    margin: 0 auto;

                    >p {
                        @include font-params (500, 16px, 19px);
                    }

                    div {
                        border-radius: 5px;
                        width: 81px;
                        height: 27px;
                        padding: 0 5px;
                        margin-right: 15px;

                        p {
                            @include font-params (400, 16px, 19px);
                        }

                        button {
                            @include font-params (500, 16px, 19px);

                            &.decrement::before {
                                position: relative;
                            }
                        }
                    }

                    .add-to-cart {
                        width: 30px;
                        height: 30px;
                        background-size: 30px;
                    }
                }
            }

            .slick-slider {
                .slick-dots {
                    position: relative;
                    top: 0;
                }

                .slick-list {
                    height: 315px;
                    width: 330px;
                    margin: auto;

                    .slick-slide {
                        width: 241px;
                    }
                }
            }

            .mobile-products__wrapper {
                display: block;
                margin: auto;

                .slick-slider {
                    .product__wrapper {
                        display: block;
                    }
                }
            }
        }
    }
}