//.catalog {
//    @include block-pos;
//    padding: 0 20px;
//    display: grid;
//    grid-template-columns: 550px 550px 550px;
//    grid-template-rows: auto;
//    grid-gap: 50px 30px;
//    padding-bottom: 70px;
//
//    .catalog-product {
//        display: block;
//        padding: 25px 20px 30px;
//        border: 1px solid #F98600;
//        border-radius: 5px;
//        text-align: center;
//
//        img {
//            max-width: 100%;
//            margin-bottom: 62px;
//        }
//
//        h3 {
//            margin-bottom: 25px;
//            text-transform: uppercase;
//            @include font-params (600, 32px, 39px);
//            text-align: left;
//        }
//
//        p {
//            @include font-params (400, 32px, 39px);
//            text-align: left;
//        }
//    }
//}
//

.lottie-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .animation {
    align-self: flex-start;
    justify-items: center;
    text-align: center;
    flex: 0 0 150px;
  }
}


//
//.flex-wrapper {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 70px;
//}
//


.brand-catalog {
  @include block-pos;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;

  a {
    flex: 1 0 350px;
    text-align: center;
    text-decoration: none;
    @include font-params(500, 24px, 27px);
    border: 1px solid #F98600;
    padding: 20px 30px;
  }
}

//@media only screen and (max-width: 1919px) and (min-width: 1024px) {
//    .catalog {
//        // @include block-pos-laptop;
//        //display: flex;
//        //flex-direction: column;
//        //justify-content: center;
//        //align-items: center;
//        //width: 100%;
//        //padding: 0 20px;
//        //grid-template-columns: 295px 295px 295px;
//        //grid-gap: 30px 20px;
//        //padding-bottom: 40px;
//        //
//        //.catalog-product {
//        //    height: 295px;
//        //    padding: 13px 5px 10px;
//        //
//        //    img {
//        //        margin-bottom: 25px;
//        //        height: 140px;
//        //    }
//        //
//        //    h3 {
//        //        margin-bottom: 5px;
//        //        @include font-params (600, 24px, 29px);
//        //    }
//        //
//        //    p {
//        //        @include font-params (400, 20px, 24px);
//        //    }
//        //}
//    }
//
//    .brand-catalog {
//        @include block-pos-laptop;
//        grid-template-columns: 216px 216px 216px 216px;
//        grid-gap: 30px 20px;
//        padding-bottom: 40px;
//
//        a {
//            display: block;
//            @include font-params (400, 22px, 27px);
//            padding: 22px 0 21px;
//        }
//    }
//}
//
//@media only screen and (max-width: 1023px) and (min-width: 280px) {
//    .catalog {
//        // @include block-pos-mobile;
//        width: 100%;
//        padding: 0 15px;
//        display: flex;
//        flex-wrap: wrap;
//        justify-content: space-between;
//        padding-bottom: 20px;
//
//        .catalog-product {
//            height: 216px;
//            padding: 5px;
//            min-width: 330px;
//
//            img {
//                width: 180px;
//                margin-bottom: 20px;
//            }
//
//            h3 {
//                margin-bottom: 10px;
//                @include font-params (600, 16px, 19px);
//            }
//
//            p {
//                @include font-params (400, 14px, 17px);
//            }
//        }
//    }
//
//    .brand-catalog {
//        @include block-pos-mobile;
//        grid-template-columns: 155px 155px;
//        grid-gap: 15px 20px;
//        padding-bottom: 20px;
//
//        a {
//            @include font-params (400, 14px, 17px);
//            padding: 20px 0;
//            color: $link_color;
//        }
//    }
//}
//
//@media only screen and (max-width: 600px) and (min-width: 280px) {
//    .catalog {
//        // @include block-pos-mobile;
//        width: 100%;
//        padding: 0 15px;
//        display: flex;
//        flex-wrap: wrap;
//        justify-content: center;
//        padding-bottom: 20px;
//    }
//}

.catalog {
  @include block-pos;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;


  .catalog-product {
    flex: 1 0 350px;
    text-align: center;
    text-decoration: none;

    .card {
      border: 1px solid #F98600;
      padding: 10px;
      transition: transform 0.2s ease;

      .image-wrapper {

        .logo-card-image {
          max-width: 150px;
          height: auto;
        }
      }

      h3 {
        font-size: 24px;
      }
    }
  }
}

