* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
}

html, body, #root, .wrapper {
    height: 100%;
}

.wrapper {
    display: flex;
    flex-direction: column;

}

a {
    text-decoration: none;
    color: $link_color;
}

ul {
    list-style-type: none;
}

button {
    background: none;
    border: none;
    cursor: pointer;
    @include font-params (400, 24px, 29px);
}
