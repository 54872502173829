.stock-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.stock-table th,
.stock-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.stock-table th {
  background-color: #f0f0f0;
  font-weight: bold;
}

.stock-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.stock-table tr:hover {
  background-color: #e0e0e0;
}
