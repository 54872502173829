.main__service-wrapper {
    background-image: url('../../../../public/images/main-page-photo.png');
    color: #fff;

    .main__service {
        display: flex;
        @include block-pos;
        justify-content: space-between;

        h1 {
            padding-top: 30px;
            @include font-params(700, 40px, 48px);
            margin-bottom: 25px;
        }

        li {
            @include font-params(600, 36px, 44px);
            margin-bottom: 25px;

            &::before {
                content: '';
                display: inline-block;
                background: url('../../../../public/images/select-arrow.svg') no-repeat center;
                background-size: 37px;
                width: 40px;
                height: 40px;
                transform: rotate(-90deg);
                vertical-align: middle;
                margin-right: 24px;
                background-position-y: 8px;
            }
        }

    }

    .order-product {
        color: $link_color;
        z-index: 3;
        padding: 192px 0 59px;

        .slick-slider {
            width: 550px;
            height: 337px;

            .product-cart {
                background-color: #fff;
                height: 337px;
                display: flex;
                padding: 45px 61px 74px 75px;

                .product-cart__info {
                    align-self: self-end;

                    h3 {
                        @include font-params (500, 32px, 39px);
                        margin-bottom: 10px;
                    }

                    p {
                        @include font-params (400, 24px, 29px);
                        margin-bottom: 17px;
                    }

                    button {
                        border: 1px solid #F98600;
                        border-radius: 5px;
                        padding: 11px 0;
                        width: 169px;
                    }
                }

            }

        }
    }

    .main-bg {
        position: absolute;
        right: 0;
        height: 588px;
        width: 318px;
        background: rgba(255, 255, 255, 0.75);
    }
}


.slick-slider {

    .slick-arrow {
        width: 110px;
        height: 63px;
        background: #F98600;
        border-radius: 5px;
        z-index: 2;
        top: 278px;

        &.slick-prev {
            left: -50px;

            &::before {
                content: '';
                display: block;
                width: 63px;
                height: 63px;
                background: url('../../../../public/images/arrow-prev.svg') no-repeat center;
                padding-left: 20px;
            }
        }

        &.slick-next {
            left: 52px;
            width: 42px;

            &::before {
                content: '';
                display: block;
                width: 35px;
                height: 63px;
                background: url('../../../../public/images/arrow-next.svg') no-repeat center;
            }
        }

    }
}

.img-wrapper {
    padding: 31px 27px;
    height: 179px;
    width: 170px;
    border: 2px solid #000000;
    border-radius: 5px;
    margin-right: 26px;

    img {
        height: 113px;
        width: 113px;
    }
}

@media only screen and (max-width: 1919px) and (min-width: 1024px) {
    .main__service-wrapper {
        .main__service {
            @include block-pos-laptop;

            h1 {
                @include font-params(700, 26px, 31px);
                padding-top: 20px;
                margin-bottom: 10px;
            }

            li {
                @include font-params(600, 24px, 29px);
                margin-bottom: 15px;

                &::before {
                    width: 30px;
                    height: 30px;
                    background-size: 26px;
                }
            }
        }

        .order-product {
            padding: 80px 0 57px;

            .slick-slider {
                width: 305px;
                height: 238px;

                .product-cart {
                    height: 238px;
                    padding: 30px 25px 53px 25px;

                    .img-wrapper {
                        height: 90px;
                        width: 90px;
                        padding: 15px;
                        margin-right: 21px;

                        img {
                            width: 60px;
                            height: 60px;
                        }
                    }

                    .product-cart__info {
                        h3 {
                            @include font-params(500, 24px, 29px);

                        }

                        p {
                            @include font-params(400, 20px, 24px);
                            margin-bottom: 20px;
                        }

                        button {
                            width: 145px;
                            height: 43px;
                            @include font-params(400, 20px, 24px);
                            padding: 10px 0;
                        }
                    }
                }
            }
        }

        .main-bg {
            height: 375px;
        }
    }

    .slick-slider {
        .slick-arrow {
            height: 40px;
            width: 75px;
            top: 202px;

            &.slick-prev {
                left: -29px;

                &::before {
                    width: 40px;
                    height: 40px;
                    padding-left: 10px;
                    background-size: 32px;

                }
            }

            &.slick-next {
                left: 37px;
                width: 32px;

                &::before {
                    height: 40px;
                    background-size: 9px;
                }
            }

        }
    }
}

@media only screen and (max-width: 1023px) and (min-width: 280px) {
    .main__service-wrapper {
        background-size: cover;
        background-repeat: no-repeat;

        .main__service {
            display: flex;
            @include block-pos-mobile;
            justify-content: space-between;
            flex-direction: column;

            h1 {
                padding-top: 15px;
                @include font-params(700, 16px, 19px);
                margin-bottom: 10px;
            }

            li {
                @include font-params(600, 14px, 17px);
                margin-bottom: 10px;

                &::before {
                    background-size: 15px;
                    width: 15px;
                    height: 15px;
                    margin-right: 5px;
                    background-position-y: 0;
                }
            }

        }

        .order-product {
            color: $link_color;
            z-index: 3;
            padding: 5px 0 24px;
            align-self: flex-end;

            .slick-slider {
                width: 150px;
                height: 126px;

                .product-cart {
                    background-color: #fff;
                    height: 126px;
                    display: flex;
                    padding: 5px;

                    .product-cart__info {
                        align-self: flex-start;
                        width: 71px;

                        h3 {
                            @include font-params (500, 14px, 17px);
                            margin-bottom: 5px;
                        }

                        p {
                            @include font-params (400, 12px, 15px);
                            margin-bottom: 13px;
                        }

                        button {
                            padding: 2.5px 0;
                            width: 101px;
                            @include font-params(400, 16px, 19px);
                            transform: translateX(-12px);
                        }
                    }

                }

            }
        }

        .main-bg {
            height: 253px;
            width: 109px;
        }
    }


    .slick-slider {

        .slick-arrow {
            width: 62px;
            height: 26px;
            top: 87px;

            &.slick-prev {
                left: -30px;
                transform: translateY(0);
                &::before {
                    content: '';
                    display: block;
                    width: 26px;
                    height: 26px;
                    background: url('../../../../public/images/arrow-prev.svg') no-repeat center;
                    padding-left: 10px;
                    background-size: 16px;
                }
            }

            &.slick-next {
                left: 0;
                width: 32px;
                transform: translateY(0);

                &::before {
                    content: '';
                    display: block;
                    width: 44px;
                    height: 26px;
                    background: url('../../../../public/images/arrow-next.svg') no-repeat center;
                    padding-right: 5px;
                    background-size: 5px;
                }
            }

        }
    }

    .img-wrapper {
        padding: 6px;
        height: 45px;
        width: 45px;
        border: 2px solid #000000;
        border-radius: 5px;
        margin-right: 6px;
        transform: translateY(4px);

        img {
            height: 30px;
            width: 30px;
        }
    }
}