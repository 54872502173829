.cart {

}
.cart__content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px;

  @media screen and (max-width: 1440px) {
    gap: 50px;
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-content: center;

  }

}
.cart__container {
  max-width: 1920px;
  margin: 0 auto auto;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;

  @media screen and (max-width: 1440px) {
    padding: 0 15px;
  }
}

.cart__items {
 // flex: 2;
}

.cart__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
 // border: 1px solid #ddd;
 // padding: 10px;
  margin-bottom: 10px;
}

.cart__item-info {
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 10px;
  width: 100%;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.cart__item-info img {
  border: 2px solid #000;
  border-radius: 5px;

  @media screen and (max-width: 767px) {
    width: 150px;
    height: 150px;
  }
}
.cart__description {
  display: flex;
  flex-direction: column;
  gap: 5px;

  .description__title {
    font-weight: 600;
    font-size: 28px;
    color: #000;

    @media screen and (max-width: 1440px) {
      font-size: 22px;
    }
    @media screen and (max-width: 450px) {
      font-size: 16px;
    }
  }
  .cart__description-subtext {
    font-weight: 400;
    font-size: 28px;
    color: #000;

    @media screen and (max-width: 1440px) {
      font-size: 18px;
    }
    @media screen and (max-width: 450px) {
      font-size: 14px;
    }
  }
}

.cart__item-price {
  font-weight: 500;
  font-size: 36px;
  color: #0da500;

  @media screen and (max-width: 1440px) {
    font-weight: 500;
    font-size: 22px;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }
}
.cart__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.cart__item-quantity {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 1px solid #f98600;
  border-radius: 5px;
}

.cart__item-quantity button {
 // background: #ddd;
  border: none;
  padding: 5px 5px;
  cursor: pointer;
}

.cart__item-quantity span {
  min-width: 20px;
  text-align: center;
}

.cart__summary {
  padding: 20px;
}

.cart__payment-method, .cart__delivery-method {
 // margin-bottom: 20px;
}
.delivery__block-select {

  select {
    font-size: 16px;
    width: 100%;
    padding: 10px 5px;
    border-color: #f98600;
  }
}

.cart__title {
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 20px;

  @media screen and (max-width: 1440px) {
    font-size: 22px;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }

}
.card__cash {
  font-weight: 600;
  font-size: 30px;
  display: flex;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: 1440px) {
    font-size: 22px;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }

  & span {
    font-weight: 500;
    font-size: 36px;
    color: #0da500;

    @media screen and (max-width: 1440px) {
      font-size: 22px;
    }
    @media screen and (max-width: 450px) {
      font-size: 16px;
    }
  }

}
.delivery__block {
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 450px) {
    gap: 15px;
  }
}

input,
textarea {
  // Стилизация самого элемента
  padding: 10px 5px;
  border: 1px solid #f98600;
  border-radius: 5px;
  font-size: 28px;
  width: 100%;
  color: #000;

  @media screen and (max-width: 1440px) {
    font-size: 22px;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }

  // Стилизация placeholder
  &::placeholder {
    color: #000;
  }

  // Дополнительные стили для фокуса
  &:focus {
    border-color: #0da500;
    outline: none;
    transition: all 0.3s;
  }
}


// Пример использования с разными элементами
.cart__delivery-method input::placeholder,
.cart__delivery-method textarea::placeholder {
  color: #888;
  font-style: italic;
}

.cart__delivery-method input:focus,
.cart__delivery-method textarea:focus {
  border-color: #ffa500;
  outline: none;
}

.cart__payment-method label, .cart__delivery-method label {
  display: block;
  margin-bottom: 10px;
}

.cart__payment-method input, .cart__delivery-method input {
  margin-right: 10px;
}

.cart__submit-button {
  background: #e76f51;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 28px;

  @media screen and (max-width: 1440px) {
    font-size: 22px;
  }
  @media screen and (max-width: 450px) {
    font-size: 16px;
  }

}

input[type="radio"] + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #ffa500; /* Оранжевая рамка */
  border-radius: 3px;
  background: white;
  transition: all 0.3s;
}

input[type="radio"]:checked + label:before {
  border-color: #ffa500; /* Оранжевая рамка при выборе */
  background: white; /* Белый фон при выборе */
  transition: all 0.3s;

}

input[type="radio"]:checked + label:after {
  content: '✓';
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
  color: #0da500; /* Зеленая галочка */
  font-size: 18px;
}
.cart__form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.cart__button {
  align-self: center;
  border-radius: 5px;
  background: #f98600;
  font-weight: 500;
  font-size: 28px;
  color: #fff;
  padding: 20px 25px;
  transition: background-color 0.3s;

  @media screen and (max-width: 1440px) {
    font-size: 22px;
  }

  @media screen and (max-width: 450px) {
    font-size: 16px;
    padding: 10px;
    width: 100%;
  }

  &:hover {
    background-color: #0da500;
  }

}


