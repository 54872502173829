.search-form {
    @include block-pos;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .search-form__params {
        width: calc(100% - 370px);

        h2 {
            @include font-params(500, 28px, 34px);
            margin-bottom: 20px;
        }

        >div {
            display: flex;
            margin-bottom: 15px;
            width: 100%;

            &:last-child {
                margin-bottom: 34px;
            }

            input {
                border: 1px solid #F98600;
                border-radius: 5px;
                outline: none;
                width: 100%;
                height: 71px;
                padding-left: 20px;
                margin-right: 49px;
                @include font-params(400, 20px, 24px);

                &::placeholder {
                    color: $link_color;
                }
            }

            button {
                border: 1px solid #F98600;
                border-radius: 5px;
                background-color: #F98600;
                color: #fff;
                width: 194px;
                height: 71px;
                @include font-params(400, 24px, 29px);
            }

            .select-wrapper {
                margin-right: 31px;
                position: relative;

                select {
                    outline: none;
                    border: 1px solid #F98600;
                    border-radius: 5px;
                    height: 71px;
                    width: 211px;
                    @include font-params(400, 20px, 24px);
                    padding: 0 20px;
                    appearance: none;

                }

                &:last-child {
                    select {
                        width: 215px;
                    }
                }

                &::after {
                    display: inline-block;
                    content: '';
                    width: 20px;
                    height: 20px;
                    background: url('../../../../public/images/select-arrow.svg') no-repeat center;
                    position: absolute;
                    right: 20px;
                    top: 25px;
                }

            }
        }

    }

    .search-form__back-call {
        text-align: center;
        width: 330px;

        a {
            @include font-params(400, 32px, 39px);
            padding-left: 5px;

            &::before {
                content: '';
                width: 40px;
                height: 40px;
                display: inline-block;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url('../../../../public/images/back-call-logo.svg');
                vertical-align: middle;
                margin-right: 15px;
                margin-bottom: 12px;
            }
        }

        p {
            @include font-params(500, 28px, 34px);
        }
    }
}

@media only screen and (max-width: 1400px) and (min-width: 1024px) {
    .search-form {
        width: 100%;

        .search-form__params {
            padding-top: 5px;

            h2 {
                @include font-params (500, 20px, 24px);
                margin-bottom: 10px;
            }

            >div {
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 20px;
                }

                input {
                    width: 533px;
                    height: 45px;
                    @include font-params (400, 16px, 19px);
                    margin-right: 15px;
                    padding-left: 10px;
                }

                button {
                    width: 139px;
                    height: 43px;
                    @include font-params (400, 20px, 24px);

                }

                .select-wrapper {
                    margin-right: 8px;

                    select {
                        width: 172px;
                        height: 43px;
                        @include font-params (400, 16px, 19px);
                        padding: 0 10px;
                    }

                    &::after {
                        right: 10px;
                        top: 12px;
                    }

                    &:last-child select {
                        width: 172px;
                    }
                }
            }
        }

        .search-form__back-call {
            width: 200px;

            a {
                @include font-params (400, 18px, 24px);

                &::before {
                    width: 30px;
                    height: 30px;
                    background-size: 30px;
                    margin-bottom: 10px;
                    margin-right: 10px;
                }
            }

            p {
                @include font-params (500, 20px, 24px);
                text-align: center;
                width: 207px;
            }
        }
    }
}

@media only screen and (max-width: 1023px) and (min-width: 300px) {
    .search-form {
        // @include block-pos-mobile;
        flex-wrap: wrap;
        padding-top: 0;
        padding: 0 15px;
        width: 100%;

        .search-form__params {
            h2 {
                @include font-params(500, 16px, 19px);
                margin-bottom: 10px;
            }

            >div {
                display: flex;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 22px;
                }

                input {
                    width: 217px;
                    height: 40px;
                    padding-left: 5px;
                    margin-right: 10px;
                    background-color: #fff;
                    @include font-params(400, 14px, 17px);
                }

                button {
                    color: #fff;
                    width: 103px;
                    height: 40px;
                    @include font-params(400, 16px, 19px);
                }

                .select-wrapper {
                    margin-right: 10px;
                    position: relative;

                    &:last-child {
                        margin: 0;
                    }

                    select {
                        outline: none;
                        height: 40px;
                        width: 93px;
                        background-color: #fff;
                        @include font-params(400, 14px, 17px);
                        padding: 0 5px;

                    }

                    &:last-child {
                        select {
                            width: 124px;
                            padding: 0 17px 0 5px;
                        }
                    }

                    &::after {
                        width: 13px;
                        height: 13px;
                        background-size: 13px;
                        right: 5px;
                        top: 13px;
                    }

                }
            }

        }

        .search-form__back-call {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 20px;

            a {
                @include font-params(400, 16px, 19px);
                padding-left: 0;
                margin-bottom: 10px;

                &::before {
                    width: 20px;
                    height: 20px;
                    background-size: 20px;
                    margin-right: 15px;
                    margin-bottom: 0;
                }
            }

            p {
                @include font-params(500, 16px, 19px);
                width: 173px;
            }
        }
    }
}

.button {
    border: 1px solid #F98600;
    border-radius: 5px;
    background-color: #F98600;
    color: #fff;
    width: 194px;
    height: 71px;
    @include font-params(400, 24px, 29px);
}